import React, { useState,useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import Notification from '../Components/Notification'
import Loading from '../Components/Loading'
import { apiRequest } from '../Redux/ApiCalls'
import checkedIcon from '../Assets/Icons/star.svg'
import uncheckedIcon from '../Assets/Icons/uncheckStar.svg'

const Reviews = () => {
  const navigate = useNavigate()
  const { currentUser } = useSelector(state => state.currentUser)
  const [reviews,setReviews] = useState([])
  const [failureMessage,setFailureMessage] = useState(null)
  const [successMessage,setSuccessMessage] = useState(null)
  const [isLoading,setIsLoading] = useState(false)
  const [isReviewsLoading,setIsReviewsLoading] = useState(false)
  const [rating,setRating] = useState(0)
  const [content,setContent] = useState('')

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!currentUser) {
      navigate('/login')
    } else {
      setIsLoading(true)
      if(!rating || !content) {
        setFailureMessage('All fields are required')
        setIsLoading(false)
        setTimeout(() => {
          setFailureMessage(null)
        }, 5000)
      } else {
        try {
          const res = await apiRequest.post('/reviews',{ rating,content },{ timeout: 30000 })
          setSuccessMessage(res.data?.message)
          setRating(0)
          setContent('')
          setIsLoading(false)
          setTimeout(() => {
            setSuccessMessage(null)
          }, 5000)
        } catch (error) {
          if (error.message) {
            setFailureMessage(error.message)
            setIsLoading(false)
          } else {
            setFailureMessage(error.response?.data.message)
            setIsLoading(false)
          }
          setTimeout(() => {
            setFailureMessage(null)
          }, 5000)
        }
      }
    }
  }

  useEffect(() => {
    const getVisibleReviews = async() => {
      setIsReviewsLoading(true)
      try {
        const res = await apiRequest.get('/reviews/public/0/6', { timeout: 30000 })
        setIsReviewsLoading(false)
        setReviews(res.data.reviews)
      } catch (error) {
        console.log(error)
        setIsReviewsLoading(false)
      }
    }
    getVisibleReviews()
  },[])

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    vertical: false,
    responsive: [
      {
        breakpoint: 1120,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  return (
    <div className='px-4 md:px-20 py-16'>
      <Notification success={successMessage} failure={failureMessage} color={successMessage? 'green' : 'red'} />
      <div className='flex flex-col items-center justify-center'>
        <h2 className='mb-4 font-bold text-3xl md:text-5xl text-center'>Please tell us how you think about us</h2>
        <form className='w-full md:w-2/3 lg:w-1/2 xl:w-1/3 p-4 border rounded-lg' onSubmit={handleSubmit}>
          <div className='flex flex-col w-full my-2'>
            <label htmlFor="email" className='mb-1 font-bold'>Please rate our product quality:</label>
            <div className='flex items-center'>
              <img data-testid='star-1' src={rating<1? uncheckedIcon:checkedIcon} alt="starIcon" className='w-12 mx-1' onClick={() => setRating(1)}/>
              <img data-testid='star-2' src={rating<2? uncheckedIcon:checkedIcon} alt="starIcon" className='w-12 mx-1' onClick={() => setRating(2)}/>
              <img data-testid='star-3' src={rating<3? uncheckedIcon:checkedIcon} alt="starIcon" className='w-12 mx-1' onClick={() => setRating(3)}/>
              <img data-testid='star-4' src={rating<4? uncheckedIcon:checkedIcon} alt="starIcon" className='w-12 mx-1' onClick={() => setRating(4)}/>
              <img data-testid='star-5' src={rating<5? uncheckedIcon:checkedIcon} alt="starIcon" className='w-12 mx-1' onClick={() => setRating(5)}/>
            </div>
          </div>
          <div className='flex flex-col w-full my-2'>
            <textarea rows="5" maxLength={350} type='text' value={content} name='comment' className='p-2 border rounded-lg'
              placeholder='Do you have any other feedback for us?' onChange={(e) => setContent(e.target.value)} />
          </div>
          <button data-testid='Submit Review' type='submit' className='px-4 py-2 text-md text-white bg-headers rounded-lg
          font-semibold shadow-sm flex items-center justify-start' disabled={isLoading}>
            {isLoading && <div className="w-full mr-2"><Loading color={'white'} /></div>}
            {isLoading? 'Submitting...' : 'Submit Review'}</button>
        </form>
      </div>
      <div className='mt-8'>
        <div className='flex flex-col items-center justify-center'>
          {reviews?.length > 0 && <h2 className='mb-4 text-4xl md:text-6xl text-center'>Read Customer Reviews</h2>}
        </div>
        {isReviewsLoading && <div data-testid='loading' className="w-full mr-2"><Loading /></div>}
        <div className='mt-4'>
          {!isReviewsLoading && !failureMessage && (
            <Slider {...settings}>
              {reviews?.map(review =>
                <div key={review.data.reviewUid} className='flex my-8 items-center justify-center'>
                  <div className='min-h-[270px] flex flex-col items-center justify-start p-4
                  rounded-lg hover:cursor-grabbing shadow-lg mb-8 border'>
                    <div className="mb-4">
                      <div className='w-[80px] h-[80px] rounded-full mb-2 bg-headers flex items-center justify-center'>
                        {review?.data.userPicture?
                          <img src={review.data.userPicture} alt='subscriberImg' className='relative w-full h-full
                          shadow-lg object-fit rounded-full' />:
                          <p className='relative w-full h-full shadow-lg rounded-full
                          text-white font-bold text-5xl flex items-center justify-center bg-headers'>{review?.data.userName?.slice(0,2).toUpperCase()}</p>}
                      </div>
                      <div className='flex items-center'>
                        <div className='flex items-center'>
                          {[...Array(review.data.rating)].map((star,index) => <img key={index} src={checkedIcon} alt='checkedIcon' />)}
                        </div>
                        <div className='flex items-center'>
                          {[...Array(5-review.data.rating)].map((star,index) => <img key={index} src={uncheckedIcon} alt='checkedIcon' />)}
                        </div>
                      </div>
                    </div>
                    <p className="text-headers font-bold mt-4">{review.data.userName}</p>
                    <p className="text-md text-text text-center">{review.data.content}</p>
                  </div>
                </div>)}
            </Slider>
          )}
        </div>
      </div>
    </div>
  )
}

export default Reviews