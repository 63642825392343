import React from 'react'
import { Link } from 'react-router-dom'

const TermsAndConditions = () => {
  return (
    <div className="px-4 md:px-20 py-8 text-sm sm:text-md md:text-xl">
      <h1 className="text-center text-4xl md:text-5xl my-8 md:my-12 lg:my-16">
        Terms and Conditions
      </h1>
      <p className="mb-4">
        <strong>Effective Date: </strong>January 15, 2024
      </p>
      <p className="mb-4">
        <strong>Last Updated: </strong>October 10, 2024
      </p>
      <div className="">
        <p>
          Welcome to the official website of{' '}
          <Link to="https://thrypes.com" target="blank">
            <strong>Thrypes GmbH.</strong>
          </Link>
          These Terms and Conditions <strong>Terms</strong> outline the rules
          for accessing and using the Thrypes GmbH website{' '}
          <Link to="https://thrypes.com" target="blank">
            <strong>https://thrypes.com</strong>
          </Link>{' '}
          By visiting or using our website, you agree to comply with these
          Terms. Please review them carefully before using the site.
        </p>
        <div className="mt-4">
          <p className="">For any inquiries, you may reach us at:</p>
          <ul className="list-disc ml-8">
            <li>
              <strong>Email: </strong>
              <a
                href="mailto:support@thrypes.com"
                className="font-semibold underline"
              >
                support@thrypes.com
              </a>
            </li>
            <li>
              <strong>Phone: </strong>
              <span>+49 (030) 25093949</span>
            </li>
          </ul>
        </div>
        <div>
          <h3 className="text-lg md:text-3xl font-bold mt-4">
            1. Acceptance of Terms
          </h3>
          <ul className="list-disc ml-8">
            <li className="">
              By accessing and using our website, you confirm acceptance of and
              compliance with these Terms. If you do not agree, please
              discontinue use of the site.
            </li>
          </ul>
        </div>
        <div>
          <h3 className="text-lg md:text-3xl font-bold mt-4">
            2. Changes to Terms
          </h3>
          <ul className="list-disc ml-8">
            <li className="">
              Thrypes may periodically update these Terms to reflect changes in
              our practices or legal requirements. Updated Terms will be posted
              with the effective date.
            </li>
            <li className="">
              Your continued use of our website after any changes indicates
              acceptance of the revised Terms.
            </li>
          </ul>
        </div>
        <div>
          <h3 className="text-lg md:text-3xl font-bold mt-4">
            3. Website Services and Purpose
          </h3>
          <ul className="list-disc ml-8">
            <li className="">
              The Thrypes corporate website provides information about our
              company, services, business updates, and legal compliance
              documentation. The website is intended for informational purposes
              only and does not offer any direct services to consumers.
            </li>
            <li className="">
              Thrypes reserves the right to modify or discontinue any features,
              content, or availability of the website without prior notice.
            </li>
          </ul>
        </div>
        <div>
          <h3 className="text-lg md:text-3xl font-bold mt-4">
            4. User Obligations and Conduct
          </h3>
          <ul className="list-disc ml-8">
            <li className="">
              Users agree to use our website responsibly and in accordance with
              all applicable laws and regulations.
            </li>
            <li className="">
              <strong className="">Prohibited Activities</strong> include, but
              are not limited to:
              <ul className="">
                <li className="">
                  Using the site for unlawful purposes or engaging in activities
                  that may disrupt site operations.
                </li>
                <li>
                  Copying, distributing, or exploiting website content for
                  commercial purposes without prior authorization.
                </li>
                <li>
                  Attempting unauthorized access to any part of the website, its
                  servers, or associated networks.
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div>
          <h3 className="text-lg md:text-3xl font-bold mt-4">
            5. Intellectual Property Rights
          </h3>
          <ul className="list-disc ml-8">
            <li className="">
              All content on the Thrypes website, including but not limited to
              text, images, logos, and software, is the property of Thrypes or
              its licensors and is protected by applicable copyright, trademark,
              and intellectual property laws.
            </li>
            <li className="">
              Users are granted a limited, non-transferable, and revocable
              license to access and use our website solely for personal,
              non-commercial purposes. Unauthorized use, copying, or
              distribution of our content is strictly prohibited.
            </li>
          </ul>
        </div>
        <div>
          <h3 className="text-lg md:text-3xl font-bold mt-4">
            6. Privacy and Data Protection
          </h3>
          <ul className="list-disc ml-8">
            <li className="">
              Thrypes values user privacy and adheres to the{' '}
              <strong className="">
                General Data Protection Regulation (GDPR)
              </strong>
              <span className="">as well as other German privacy laws.</span>
            </li>
            <li className="">
              Our{' '}
              <Link to="/privacyPolicy" target="blank">
                <strong className="">Privacy Policy</strong>
              </Link>
              details how we collect, process, and protect personal data,
              including the data collected through your interaction with our
              website.
            </li>
            <li className="">
              By using our website, you agree to our privacy practices as
              outlined in our Privacy Policy.
            </li>
          </ul>
        </div>
        <div>
          <h3 className="text-lg md:text-3xl font-bold mt-4">
            7. Cookie Policy
          </h3>
          <ul className="list-disc ml-8">
            <li className="">
              Thrypes uses cookies and similar technologies to enhance user
              experience, analyze site usage, and facilitate targeted
              advertising.
            </li>
            <li className="">
              Our{' '}
              <Link to="/cookiesPolicy" target="blank">
                <strong className=""> Cookie Policy</strong>
              </Link>
              provides information on the types of cookies we use, their
              purposes, and how you can manage your preferences.
            </li>
            <li>
              By accessing our website, you agree to our use of cookies as
              described.
            </li>
          </ul>
        </div>
        <div>
          <h3 className="text-lg md:text-3xl font-bold mt-4">
            8. Disclaimer of Warranties
          </h3>
          <ul className="list-disc ml-8">
            <li className="">
              The Thrypes website and its content are provided on an “as-is”
              basis, without any warranties, express or implied, including but
              not limited to accuracy, reliability, and suitability for any
              particular purpose.
            </li>
            <li className="">
              Thrypes does not guarantee uninterrupted access, error-free
              functionality, or complete security. Use of our website is at your
              own risk.
            </li>
          </ul>
        </div>
        <div>
          <h3 className="text-lg md:text-3xl font-bold mt-4">
            9. Limitation of Liability
          </h3>
          <ul className="list-disc ml-8">
            <li className="">
              To the fullest extent permitted by law, Thrypes disclaims any
              liability for direct, indirect, incidental, consequential, or
              punitive damages resulting from the use or inability to use our
              website.
            </li>
            <li className="">
              This limitation applies to damages caused by unauthorized access,
              data loss, service interruption, and any other similar harm.
            </li>
          </ul>
        </div>
        <div>
          <h3 className="text-lg md:text-3xl font-bold mt-4">
            10. Third-Party Links and Content
          </h3>
          <ul className="list-disc ml-8">
            <li className="">
              Our website may contain links to external websites or services
              managed by third parties. Thrypes provides these links for
              convenience and does not endorse or assume responsibility for
              content on these sites.
            </li>
            <li className="">
              Users are responsible for reviewing third-party terms and policies
              before interacting with external websites.
            </li>
          </ul>
        </div>
        <div>
          <h3 className="text-lg md:text-3xl font-bold mt-4">
            11. Indemnification
          </h3>
          <ul className="list-disc ml-8">
            <li className="">
              By using the Thrypes website, you agree to indemnify and hold
              harmless Thrypes, its affiliates, and employees from any claims,
              losses, or damages arising from your use of our site or any breach
              of these Terms.
            </li>
          </ul>
        </div>
        <div>
          <h3 className="text-lg md:text-3xl font-bold mt-4">
            12. Termination of Access
          </h3>
          <ul className="list-disc ml-8">
            <li className="">
              Thrypes reserves the right to terminate or restrict user access to
              our website at any time without prior notice, particularly in
              cases of suspected misuse, unauthorized activities, or violations
              of these Terms.
            </li>
          </ul>
        </div>
        <div>
          <h3 className="text-lg md:text-3xl font-bold mt-4">
            13. Governing Law and Jurisdiction
          </h3>
          <ul className="list-disc ml-8">
            <li className="">
              These Terms are governed by the laws of <strong>Germany</strong>{' '}
              and the <strong>European Union’s GDPR</strong>. Any disputes will
              be resolved under the jurisdiction of German courts located in
              Berlin.
            </li>
            <li>
              By agreeing to these Terms, users consent to the jurisdiction of
              German courts and acknowledge that local regulations may apply in
              addition to these Terms.
            </li>
          </ul>
        </div>
        <div>
          <h3 className="text-lg md:text-3xl font-bold mt-4">
            14. Entire Agreement
          </h3>
          <ul className="list-disc ml-8">
            <li className="">
              These Terms, along with our Privacy and Cookie Policies,
              constitute the complete agreement between Thrypes GmbH and users
              concerning the use of our website.
            </li>
          </ul>
        </div>
        <div>
          <h3 className="text-lg md:text-3xl font-bold mt-4">
            15. Severability
          </h3>
          <ul className="list-disc ml-8">
            <li className="">
              If any provision of these Terms is found invalid or unenforceable,
              the remaining provisions will remain in effect.
            </li>
          </ul>
        </div>
        <div>
          <h3 className="text-lg md:text-3xl font-bold mt-4">
            Contact Information
          </h3>
          <p className="">
            For questions or concerns regarding these Terms and Conditions,
            please reach out to us:
          </p>
          <ul className="list-disc ml-8">
            <li>
              <strong>Thrypes GmbH</strong>
            </li>
            <li>
              <strong>Address: </strong>
              <span>Gontardstraße 11, 4th Floor, 10178 Berlin, Germany</span>
            </li>
            <li>
              <strong>Email: </strong>
              <a
                href="mailto:support@thrypes.com"
                className="font-semibold underline"
              >
                support@thrypes.com
              </a>
            </li>
            <li>
              <strong>Phone: </strong>
              <span>+49 (030) 25093949</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default TermsAndConditions
