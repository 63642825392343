import React from 'react'
import { Link } from 'react-router-dom'

const CookiesPolicy = () => {
  return (
    <div className="px-4 md:px-20 py-8 text-sm sm:text-md md:text-xl">
      <h1 className="text-center text-4xl md:text-5xl my-8 md:my-12 lg:my-16">
        Cookies Policy
      </h1>
      <p className="mb-4">
        <strong>Effective Date: </strong>August 20, 2024
      </p>
      <p className="mb-4">
        <strong>Last Updated: </strong>October 10, 2024
      </p>
      <div>
        <p>
          This Cookie Policy explains
          <Link to="https://thrypes.com" target="blank">
            <strong>Thrypes GmbH</strong>
          </Link>
          uses cookies and similar technologies on our corporate website. By
          using our website, you agree to our use of cookies as outlined below.
        </p>
        <div>
          <h3 className="text-lg md:text-3xl font-bold mt-4">
            1. What Are Cookies?
          </h3>
          <ul className="list-disc ml-8">
            <li>
              Cookies are small text files stored on your device (computer,
              tablet, smartphone) when you visit websites. Cookies help enhance
              the user experience by enabling the website to remember your
              preferences, login status, and past actions.
            </li>
            <li>
              Cookies can be classified as session cookies, which expire when
              you close your browser, or persistent cookies, which remain on
              your device for a specified period or until manually deleted.
            </li>
          </ul>
        </div>
        <div>
          <h3 className="text-lg md:text-3xl font-bold mt-4">
            2. Purpose of Cookies Used by Thrypes
          </h3>
          <p>
            Thrypes uses cookies to optimize website functionality, analyze user
            behavior, and personalize content. Specifically, cookies on our
            website serve the following purposes:
          </p>
          <ul className="list-disc ml-8">
            <li>
              <strong>Essential Cookies:</strong>
              <span className="">
                Enable core website functions, ensuring proper performance and
                security.
              </span>
            </li>
            <li>
              <strong>Performance and Analytics Cookies:</strong>
              <span className="">
                Collect data on website usage, helping us understand visitor
                interactions and improve site functionality.
              </span>
            </li>
            <li>
              <strong>Functional Cookies:</strong>
              <span className="">
                Remember your preferences and settings, enhancing your
                experience on repeat visits.
              </span>
            </li>
            <li>
              <strong>Targeting and Advertising Cookies:</strong>
              <span className="">
                Help tailor advertising to user interests. While our website may
                include links to third-party sites, Thrypes is not responsible
                for external tracking practices.
              </span>
            </li>
          </ul>
        </div>
        <div>
          <h3 className="text-lg md:text-3xl font-bold mt-4">
            3. Types of Cookies We Use
          </h3>
          <p className="">
            Below is an overview of the types of cookies used on the Thrypes
            website:
          </p>
          <div>
            <h3 className="text-md md:text-2xl font-bold mt-2">
              Strictly Necessary Cookies
            </h3>
            <p>
              These cookies are essential for website functionality and cannot
              be disabled in our systems. They enable basic operations, such as
              navigating the site and accessing secure areas.
            </p>
          </div>
          <div>
            <h3 className="text-md md:text-2xl font-bold mt-2">
              Analytical/Performance Cookies
            </h3>
            <p>
              These cookies help us understand how users interact with our site
              by collecting anonymized data on metrics like page views, time
              spent, and interactions. This information aids in optimizing user
              experience.
            </p>
          </div>
          <div>
            <h3 className="text-md md:text-2xl font-bold mt-2">
              Functional Cookies
            </h3>
            <p>
              Functional cookies remember your site preferences (such as
              language settings) to offer a more personalized experience on our
              website.
            </p>
          </div>
          <div>
            <h3 className="text-md md:text-2xl font-bold mt-2">
              Targeting/Advertising Cookies
            </h3>
            <p>
              Thrypes may occasionally use targeting cookies to tailor
              advertising content relevant to our users’ interests. These
              cookies may track visitors across websites and build a profile of
              your interests to ensure relevant ads.
            </p>
          </div>
        </div>
        <div>
          <h3 className="text-lg md:text-3xl font-bold mt-4">
            4. Third-Party Cookies
          </h3>
          <ul className="list-disc ml-8">
            <li>
              Thrypes may include cookies from trusted third-party partners to
              assist in analyzing user behavior and delivering personalized
              content.
            </li>
            <li>
              These third-party cookies may come from analytics services like{' '}
              <strong>Google Analytics</strong> or advertising providers and are
              subject to the policies of the respective providers.
            </li>
          </ul>
        </div>
        <div>
          <h3 className="text-lg md:text-3xl font-bold mt-4">
            5. Managing Your Cookie Preferences
          </h3>
          <p>
            Users can adjust cookie settings by navigating to their browser’s
            settings or preferences. Each browser provides options to block or
            delete cookies. Note that disabling certain cookies may impact
            website performance and limit some functionalities.
          </p>
          <div className="">
            <h3 className="text-md md:text-2xl font-bold mt-2">
              Common Browser Cookie Settings:
            </h3>
            <ul className="list-disc ml-8">
              <li>
                <strong>For Chrome:</strong>
                <p>
                  Settings &gt; Privacy and Security &gt; Cookies and other site
                  data
                </p>
              </li>
              <li>
                <strong>For Firefox:</strong>
                <p>
                  Options &gt; Privacy & Security &gt; Cookies and Site Data
                </p>
              </li>
              <li>
                <strong>For Safari:</strong>
                <p>Preferences &gt; Privacy &gt; Manage Website Data</p>
              </li>
              <li>
                <strong>For Edge:</strong>
                <p>
                  {' '}
                  Settings &gt; Site Permissions &gt; Cookies and site data
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div>
          <h3 className="text-lg md:text-3xl font-bold mt-4">
            6. Legal Basis for Cookie Usage
          </h3>
          <ul className="list-disc ml-8">
            <li>
              In accordance with the{' '}
              <strong>General Data Protection Regulation (GDPR)</strong> and{' '}
              <strong>German privacy regulations</strong>, Thrypes processes
              cookies with users’ consent, except for those strictly necessary
              for website operation. By accessing our site and selecting cookie
              preferences, users acknowledge and provide consent for cookie use
              as described.
            </li>
            <li>
              Users may withdraw or adjust their consent by modifying browser
              settings or updating cookie preferences on our website.
            </li>
          </ul>
        </div>
        <div>
          <h3 className="text-lg md:text-3xl font-bold mt-4">
            7. Data Retention and Security
          </h3>
          <ul className="list-disc ml-8">
            <li>
              Thrypes employs secure data management practices and ensures that
              cookies are stored and processed in compliance with GDPR. Data
              collected via cookies will be retained only as necessary for its
              specific purpose and will be deleted after the retention period
              expires.
            </li>
            <li>
              We implement robust security measures to safeguard user data and
              prevent unauthorized access, disclosure, or misuse of personal
              data.
            </li>
          </ul>
        </div>
        <div>
          <h3 className="text-lg md:text-3xl font-bold mt-4">
            8. Updates to This Cookie Policy
          </h3>
          <ul className="list-disc ml-8">
            <li>
              Thrypes may revise this Cookie Policy to reflect legal
              requirements, technological advancements, or changes in our
              business practices. Any updates will be posted here with the{' '}
              <strong>Last Updated</strong> date. We recommend reviewing this
              policy periodically to stay informed on our cookie usage
              practices.
            </li>
          </ul>
        </div>
        <div>
          <h3 className="text-lg md:text-3xl font-bold mt-4">
            9. Contact Information
          </h3>
          <p className="">
            For inquiries regarding this Cookie Policy or data protection at
            Thrypes, please contact:
          </p>
          <ul className="list-disc ml-8">
            <li>
              <strong>Thrypes GmbH</strong>
            </li>
            <li>
              <strong>
                Gontardstraße 11, 4th Floor, 10178 Berlin, Germany
              </strong>
            </li>
            <li>
              <strong>Email: </strong>
              <a
                href="mailto:data.privacy@thrypes.com"
                className="font-semibold underline"
              >
                data.privacy@thrypes.com
              </a>
            </li>
            <li>
              <strong>Phone: </strong>
              <span>+49 (030) 25093949</span>
            </li>
          </ul>
          <p className="mt-4">
            By continuing to use the Thrypes corporate website, you agree to our
            use of cookies and the terms outlined in this Cookie Policy.
          </p>
        </div>
      </div>
    </div>
  )
}

export default CookiesPolicy
