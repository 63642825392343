import React,{ useState, useRef, useEffect } from 'react'
import { Link } from 'react-router-dom'

import { Sercives } from '../Constants/Services'
import { apiRequest } from '../Redux/ApiCalls'
import Loading from '../Components/Loading'
import Notification from '../Components/Notification'
import HeroImg from '../Assets/Images/HiroImg.png'
import Dots from '../Assets/Images/dots.png'
// import googleCloud from '../Assets/Images/google_cloud.png'
// import azure from '../Assets/Images/microsoft.png'
// import aws from '../Assets/Images/aws.png'
import video from '../Assets/Images/video.mp4'
import videoImg from '../Assets/Images/cloud.jpg'
import checkedIcon from '../Assets/Icons/star.svg'
import uncheckedIcon from '../Assets/Icons/uncheckStar.svg'
import play from '../Assets/Icons/play.svg'

const Home = () => {
  const videoRef = useRef(null)
  const [reviews,setReviews] = useState([])
  const visibleReviews = reviews?.slice(0,6)
  const [subscriber,setSubscriber] = useState('')
  const [isPlaying, setIsPlaying] = useState(false)
  const [review,setReview] = useState()
  const [reviewsLoading, setReviewsLoading] = useState(false)
  const [subscriptionLoading, setSubscriptionLoading] = useState(false)
  const [failureMessage,setFailureMessage] = useState(null)
  const [successMessage,setSuccessMessage] = useState(null)


  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause()
    } else {
      videoRef.current.play()
      setIsPlaying(true)
    }
  }

  const handleSubscriber = async(e) => {
    e.preventDefault()
    if(subscriber !== '') {
      setSubscriptionLoading(true)
      try {
        const res = await apiRequest.post('/subscribers',{ email:subscriber },{ timeout: 30000 })
        setSuccessMessage(res.data?.message)
        setSubscriber('')
        setSubscriptionLoading(false)
        setTimeout(() => {
          setSuccessMessage(null)
        }, 5000)
      } catch (error) {
        if (error.response) {
          setFailureMessage(error.response?.data.message)
        } else {
          setFailureMessage(error.message)
        }
        setSubscriptionLoading(false)
        setTimeout(() => {
          setFailureMessage(null)
        }, 5000)
      }
    } else {
      setFailureMessage('Email is required')
      setTimeout(() => {
        setFailureMessage(null)
      }, 5000)
    }
  }

  const changeReview =(reviewId) => {
    const currentReview = visibleReviews.find(clientsReview => clientsReview.data.reviewUid === reviewId)
    setReview(currentReview)
  }

  useEffect(() => {
    const getVisibleReviews = async() => {
      setReviewsLoading(true)
      try {
        const res = await apiRequest.get('/reviews/public/0/6', { timeout: 30000 })
        setReviews(res.data.reviews)
        setReview(res.data?.reviews[0])
        setReviewsLoading(false)
      } catch (error) {
        console.log(error)
        setReviewsLoading(false)
      }
    }
    getVisibleReviews()
  },[])

  const ServiceCard = ({ service }) => (
    <Link to={`/services/${service.title}`} className='w-[270px] min-h-[300px] m-2 shadow-xl bg-white rounded-2xl
    flex flex-col items-center justify-start'>
      <img src={service.image} alt={service.title} className='w-full h-[150px] object-cover  shadow rounded-t-2xl brightness-50' />
      <h3 className='text-black text-2xl font-bold text-center'>{service.title}</h3>
      <p className='text-center text-gray-500 px-4 py-2'>{service.description.slice(0,150)}...</p>
    </Link>
  )

  return (
    <div>
      <Notification success={successMessage} failure={failureMessage} color={successMessage? 'green' : 'red'} />
      <div className='flex flex-col-reverse lg:flex-row items-center justify-center mb-8'>
        <div className='flex-1 relative px-4 md:pl-20 md:pr-20 lg:pr-0 z-10 flex flex-col items-center lg:items-start'>
          <img src={Dots} alt='dotsImg' className='lg:w-1/3 h-32 absolute -bottom-16'/>
          <div className='relative lg:w-[90%] mt-8 lg:mt-0 flex flex-col items-center lg:items-start'>
            <h2 className='text-4xl sm:text-5xl text-headers mb-4 font-bold text-center lg:text-left'>Chart the path for creating innovative,
            seamless and simple solutions.</h2>
            <p className='text-md text-text text-center lg:text-left'>Expanding possibilities by adopting cutting edge technology and innovations
               to create simple solutions to everyday problems. We are paving the way and pushing
                the boundaries of quality of service, easy of use and versatility through
                our every service offering. Our diversity and huge wealth of experience is an
                 advantage that we are leveraging to create state of the art solutions built
                  specifically fit the market.</p>
            <Link to='/contacts'>
              <button className='bg-headers text-white px-8 py-2 hover:px-10 text-lg rounded-full mt-4 ease-in-out duration-300'>
                Contact Now
              </button>
            </Link>
          </div>
        </div>
        <div className='flex-1 relative w-full'>
          <div className='bg-extention w-[120px] h-[160px] absolute -bottom-16 right-48 rounded-br-[50%]'></div>
          <div className='bg-extention w-[100px] h-[100px] absolute -left-12 top-0 bottom-20 rounded-full'></div>
          <img src={HeroImg} alt='heroImg' className='relative lg:min-h-[60vh] max-h-[65vh] w-full lg:rounded-bl-[30%] object-cover' />
        </div>
      </div>
      {/* <div className='px-4 md:px-20 my-16 py-8 flex flex-col md:flex-row items-center justify-center md:justify-between'>
        <div className='flex flex-col items-center md:items-start justify-center md:justify-start'>
          <h3 className='text-3xl mb-2 font-bold'>Our Partners</h3>
          <p className='text-lg w-2/3 text-text text-center md:text-left'>Several selected partners, who already believe in our service.</p>
        </div>
        <div className='flex flex-col sm:flex-row items-center justify-center'>
          <img src={googleCloud} alt='google_cloud-Logo' className='w-36 m-4' />
          <img src={azure} alt='azure-logo' className='w-36 m-4' />
          <img src={aws} alt='aws-logo' className='w-32 m-4' />
        </div>
      </div> */}
      <div className='relative flex flex-col lg:flex-row items-center justify-between py-8'>
        <div className='bg-extention w-4/5 absolute right-0 top-20 bottom-20 rounded-l-3xl'></div>
        <div className='flex-1 relative px-4 md:pl-20 md:pr-20 lg:pr-0'>
          <div className='bg-alternative w-[80px] h-[120px] absolute -top-16 left-4 md:left-20 rounded-tr-[50%]'></div>
          <img src={Dots} alt='dotsImg' className='absolute w-2/3 sm:w-1/3 -bottom-12'/>
          <div className='relative w-full md:w-4/5'>
            <h2 className='text-3xl md:text-4xl mb-4 font-bold'>We pride ourselves in our profound ability to understand
              complex problems and create simple, feasible and adaptive solutions.</h2>
            <p className='text-lg text-text'>Our versatile experience allows us a better
            horizon to view, understand and perceive diverse innovative solutions.</p>
          </div>
        </div>
        <div className='px-4 md:pr-20 md:pl-20 lg:pl-0 pt-8 lg:pt-0 relative flex-1 flex flex-col sm:flex-row
        items-start justify-center lg:items-center lg:justify-end'>
          <div className='mt-32'>{Sercives.slice(0, 1).map(service => <ServiceCard key={service.id} service={service} />)}</div>
          <div>{Sercives.slice(1).map(service => <ServiceCard key={service.id} service={service} />)}</div>
        </div>
      </div>
      <div className='flex flex-col-reverse lg:flex-row px-4 py-8 md:p-20 items-center'>
        <div className='flex-1 relative lg:pr-4 mt-8 lg:mt-0'>
          <div className='bg-[#FFF5DB] w-[100px] h-[100px] absolute -bottom-16 right-4 rounded-[50%]'></div>
          <div className='relative max-h-[320px] md:w-2/3 mx-auto lg:w-4/5'>
            <video ref={videoRef} className={`shadow-2xl rounded-2xl h-full w-full ${isPlaying ? 'flex':'hidden'}`}
              controls onPlay={() => setIsPlaying(true)}>
              <source src={video} type='video/mp4'  />
            </video>
            <img src={videoImg} alt='videoplayImg' className={`brightness-50 shadow-2xl rounded-2xl h-full w-full
             object-cover ${!isPlaying ? 'flex':'hidden'}`} />
            {!isPlaying &&
            <button data-testid='videoplayer' onClick={handlePlayPause} className='w-[50px] h-[50px] bg-white absolute top-0 bottom-0
             left-0 right-0 m-auto rounded-full flex items-center justify-center'>
              <img src={play} alt='playerIcon' />
            </button>}
          </div>
        </div>
        <div className='flex-1 relative lg:pl-4'>
          <div className='bg-extention w-[80px] h-[120px] absolute -top-12 rounded-br-[50%]'></div>
          <div className='relative'>
            <h2 className='text-3xl md:text-4xl mb-4 font-bold'>We are a cloud native company constantly looking for ways
             to leverage latest cutting edge tools, technologies and methodologies.</h2>
            <p className='text-lg text-text'>In the core of our systems, we emphasize security,
            high availability, fault-tolerance and redundancy.</p>
          </div>
        </div>
      </div>
      <div className='flex flex-col items-center justtify center px-4 py-8 md:px-20'>
        {reviews?.length > 0 &&
        <div className='mb-4'>
          <h2 className='text-4xl mb-4 font-bold text-center'>What our happy client say.</h2>
          <p className='text-lg text-text text-center'>Several selected clients, who already believe in our service.</p>
        </div>
        }
        {reviewsLoading && <div className="w-full"><Loading /></div>}
        {reviews?.length !== 0 &&
        <div className='flex items-center flex-col lg:flex-row justify-between mt-8 w-full'>
          <div className='flex-1 relative m-4 flex items-center justify-center'>
            <div className='border-4 border-strong w-[80px] h-[160px] absolute -bottom-4 lg:-bottom-10 left-12 rounded-[50%]'></div>
            <img src={Dots} alt='dotsImg' className='absolute lg:w-1/2 -top-10 right-0'/>
            {review?.data.userPicture?
              <img src={review.data.userPicture} alt='subscriberImg' className='relative w-[150px] h-[150px] md:w-[200px] md:h-[200px]
              shadow-lg object-fit rounded-b-full rounded-l-full' />:
              <p className='relative w-[150px] h-[150px] md:w-[200px] md:h-[200px] shadow-lg rounded-b-full rounded-l-full
              text-white font-bold text-7xl flex items-center justify-center bg-headers'>{review?.data.userFirstName?.slice(0,2).toUpperCase()}</p>}
          </div>
          <div className='flex-1 m-4 flex flex-col items-center justify-center'>
            <div className='mb-2 flex flex-col items-center justify-center'>
              <h4 className='text-xl font-bold'>{review?.data.userFirstName} {review?.data.userLastName}</h4>
              <div className='flex items-center'>
                <div className='flex items-center'>
                  {[...Array(review?.data.rating)].map((star,index) => <img key={index} src={checkedIcon} alt='checkedIcon' />)}
                </div>
                <div className='flex items-center'>
                  {[...Array(5-review?.data.rating)].map((star,index) => <img key={index} src={uncheckedIcon} alt='checkedIcon' />)}
                </div>
              </div>
            </div>
            <p className='text-md text-text w-[90%] text-center'>{review.data.content}</p>
          </div>
          <div className='flex-1 m-4 flex items-center justify-center'>
            <div className='relative w-[300px] h-[300px] flex items-center justify-center'>
              <div className='bg-[#FFF5DB] w-[100px] h-[100px] absolute bottom-32 left-24 rounded-[50%]'></div>
              <div className='absolute w-[100px] h-[100px]'>
                {review.data.userPicture?
                  <img src={review?.data.userPicture} alt='clientImg' className='w-full h-full object-cover rounded-full shadow-lg'/>:
                  <p className='w-full h-full text-white font-bold text-5xl bg-headers rounded-full shadow-lg flex items-center justify-center'>
                    {review.data.userFirstName?.slice(0,2).toUpperCase()}</p>}
              </div>
              {visibleReviews.map((review,index) =>
                <div key={review.data.reviewUid} className={`absolute left-0 w-[60px] h-[60px] border rounded-full rotate-[${60 * index}deg] origin-[150px] cursor-pointer`}
                  onClick={() => changeReview(review.data.reviewUid)}>
                  {review.data.userPicture?
                    <img src={review.data.userPicture} alt='clientImg' className={`w-full h-full object-fit rounded-full shadow-lg ${index === 1? '-rotate-60'
                      :index === 2?'-rotate-120': index === 3?'-rotate-180':index === 4?'-rotate-240'
                        :index === 5?'-rotate-300':'-rotate-360'}`}/>:
                    <p className={`w-full h-full text-white font-bold text-2xl bg-headers rounded-full shadow-lg flex items-center justify-center 
                    ${index === 1? '-rotate-60' :index === 2?'-rotate-120': index === 3?'-rotate-180':index === 4?'-rotate-240'
      :index === 5?'-rotate-300':'-rotate-360'}`} >{review.data.userFirstName?.slice(0,2).toUpperCase()}</p>}
                </div>
              )}
              {/* <div className='absolute left-0 w-[50px] h-[50px] border rounded-full bg-slate-700 rotate-[60deg] origin-[150px]'></div>
              <div className='absolute left-0 w-[50px] h-[50px] border rounded-full bg-slate-700 rotate-[120deg] origin-[150px]'></div>
              <div className='absolute left-0 w-[50px] h-[50px] border rounded-full bg-green-700 rotate-[180deg] origin-[150px]'></div>
              <div className='absolute left-0 w-[50px] h-[50px] border rounded-full bg-red-700 rotate-[240deg] origin-[150px]'></div>
              <div className='absolute left-0 w-[50px] h-[50px] border rounded-full bg-yellow-700 rotate-[300deg] origin-[150px]'></div>
              <div className='absolute left-0 w-[50px] h-[50px] border rounded-full bg-teal-700 rotate-[360deg] origin-[150px]'></div>     */}
            </div>
          </div>
        </div>}
      </div>
      <div className='px-4 py-8 md:px-20'>
        <div className='relative flex items-center justify-center'>
          <div className='bg-alternative absolute w-[80px] h-[120px] bottom-0 left-0 rounded-bl-[50px]'></div>
          <img src={Dots} alt='dotsImg' className='absolute w-1/3 top-0 right-0'/>
          <div className='relative bg-extention m-2 sm:m-8 w-full h-[200px] rounded-[50px] flex items-center justify-center'>
            <div className='hidden lg:flex bg-strong w-2/5 h-full absolute right-0 rounded-l-[300px] rounded-r-[190px]'></div>
            <div className='relative w-full flex flex-col lg:flex-row items-center justify-around px-4'>
              <div className='mb-4 lg:mb-0'>
                <h3 className='text-2xl sm:text-4xl mb-2 font-bold text-center'>Subscribe Newsletter</h3>
                <p className='text-md text-text text-center'>I will update good news and promotion service not spam.</p>
              </div>
              <form className='flex items-center bg-white pl-2 py-1 rounded-[100px]' onSubmit={handleSubscriber}>
                <input
                  maxLength='50'
                  type='email'
                  placeholder='Enter your email address'
                  value={subscriber}
                  className='bg-white p-2 text-black w-full sm:w-72 focus:outline-none'
                  onChange={(e) => setSubscriber(e.target.value)} />
                <button
                  data-testid='subscriber'
                  type='submit'
                  className='flex items-center justify-center px-4 py-2 bg-headers hover:bg-extention hover:text-black
                   text-white text-lg font-[700] rounded-[100px] ease-in-out duration-300'
                  disabled={subscriptionLoading}>
                  {subscriptionLoading && <Loading color={'white'} />}
                  <div className='ml-2'>Contact<span className='hidden sm:inline ml-1'>Now</span></div>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home