import React from 'react'
import { Link } from 'react-router-dom'

const PrivacyPolicy = () => {
  return (
    <div className="px-4 md:px-20 py-8 text-sm sm:text-md md:text-xl">
      <h1 className="text-center text-4xl md:text-5xl my-8 md:my-12 lg:my-16">
        Privacy Policy
      </h1>
      <div>
        <p className="mb-4">
          <strong>Effective Date:</strong>August 20, 2024
        </p>
        <p className="mb-4">
          <strong>Last Updated:</strong>October 10, 2024
        </p>
        <p>
          <Link to="https://thrypes.com" target="blank">
            <strong>Thrypes GmbH</strong>
          </Link>{' '}
          (“Thrypes,” “we,” “us,” or “our”) operates the corporate website
          Thrypes and is committed to protecting the privacy and personal data
          of our users. This Privacy Policy explains how we collect, use,
          disclose, and protect information on our website and describes your
          rights under applicable privacy laws, including the{' '}
          <strong>General Data Protection Regulation (GDPR)</strong> and{' '}
          <strong>German data protection law (BDSG).</strong>
        </p>
        <div>
          <h3 className="text-lg md:text-3xl font-bold mt-4">1. Who We Are</h3>
          <div>
            <ul className="list-disc ml-8">
              <li>
                <strong>Thrypes GmbH </strong>is a company registered in Germany
                with a mission to deliver professional services and solutions to
                our customers.
              </li>
              <li>
                Our registered office is located at{' '}
                <strong>
                  Gontardstraße 11, 4th Floor, 10178 Berlin, Germany.
                </strong>
              </li>
              <li>
                For any questions about this Privacy Policy, you may contact us
                by email at{' '}
                <a
                  href="mailto:data.privacy@thrypes.com"
                  className="font-semibold underline"
                >
                  data.privacy@thrypes.com
                </a>{' '}
                or by phone at <strong>+49 (030) 25093949.</strong>
              </li>
            </ul>
          </div>
        </div>
        <div>
          <h3 className="text-lg md:text-3xl font-bold mt-4">
            2. Scope of This Privacy Policy
          </h3>
          <div className="">
            <ul className="list-disc ml-8">
              <li>
                This Privacy Policy applies solely to personal data collected
                through the Thrypes corporate website (
                <Link to="https://thrypes.com" target="blank">
                  <strong>Thrypes GmbH</strong>
                </Link>{' '}
                ) and does not extend to any other websites or third-party
                services that may be linked on our website.
              </li>
              <li>
                By using our website, you consent to the practices described in
                this Privacy Policy.
              </li>
            </ul>
          </div>
        </div>
        <div>
          <h3 className="text-lg md:text-3xl font-bold mt-4">
            3. What Information We Collect
          </h3>
          <p>
            Thrypes collects personal data to provide and improve our website
            and services. We may collect the following types of information:
          </p>
          <div className="">
            <ul className="list-disc ml-8">
              <li>
                <strong>Personal Identification Information: </strong>
                <span className="">
                  {' '}
                  Including but not limited to name, email address, phone
                  number, and postal address when voluntarily provided via
                  contact forms, newsletter sign-ups, or other requests.
                </span>
              </li>
              <li>
                <strong>Technical and Usage Data: </strong>
                <span className="">
                  Such as IP address, browser type, device information, pages
                  visited, time spent on the site, and other analytical
                  information to enhance user experience and analyze trends.
                </span>
              </li>
              <li>
                <strong>Cookies and Similar Technologies: </strong>
                <span className="">
                  Our website uses cookies to improve functionality and gather
                  aggregate data on site usage. Please see our{' '}
                  <Link to="/cookiesPolicy" target="blank">
                    <strong>Cookie Policy</strong>
                  </Link>{' '}
                  for more details.
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div>
          <h3 className="text-lg md:text-3xl font-bold mt-4">
            4. How We Use Your Information
          </h3>
          <p>
            Thrypes uses your information to fulfill legitimate business
            purposes and provide a positive user experience. Specifically, we
            may use your information for:
          </p>
          <ul className="list-disc ml-8">
            <li>
              <strong>Communication: </strong>
              <span className="">
                Responding to inquiries, providing requested services, and
                offering customer support.
              </span>
            </li>
            <li>
              <strong>Improvement of Services: </strong>
              <span className="">
                Analyzing website usage and trends to improve our services,
                develop new features, and enhance overall user experience.
              </span>
            </li>
            <li>
              <strong>Marketing Communications: </strong>
              <span className="">
                Sending occasional updates or newsletters to users who have
                opted in, with the option to unsubscribe at any time.
              </span>
            </li>
            <li>
              <strong>Compliance and Security: </strong>
              <span className="">
                Ensuring our compliance with legal obligations, protecting our
                website and services from fraud or abuse, and safeguarding the
                privacy of our users.
              </span>
            </li>
          </ul>
        </div>
        <div>
          <h3 className="text-lg md:text-3xl font-bold mt-4">
            5. Legal Basis for Processing Personal Data
          </h3>
          <p>Under GDPR, our legal bases for processing personal data are:</p>
          <ul className="list-disc ml-8">
            <li>
              <strong>Consent: </strong>
              <span className="">
                When you voluntarily provide personal information through
                contact forms, newsletter sign-ups, or other means, you provide
                consent to process your information for communication and
                marketing purposes.
              </span>
            </li>
            <li>
              <strong>Legitimate Interests: </strong>
              <span className="">
                We may process personal data to improve our services, prevent
                fraud, and maintain security.
              </span>
            </li>
            <li>
              <strong>Legal Compliance: </strong>
              <span className="">
                Processing is necessary to comply with legal obligations,
                including data protection, fraud prevention, and regulatory
                requirements.
              </span>
            </li>
          </ul>
        </div>
        <div>
          <h3 className="text-lg md:text-3xl font-bold mt-4">
            6. Disclosure of Your Information
          </h3>
          <p>
            Thrypes will only share personal data when necessary, and we do not
            sell, trade, or rent personal data to third parties.
          </p>
          <ul className="list-disc ml-8">
            <li>
              <strong>Service Providers: </strong>
              <span className="">
                We may share information with trusted third-party providers who
                assist in operating our website or providing services. These
                providers adhere to strict confidentiality agreements.
              </span>
            </li>
            <li>
              <strong>Legal Obligations: </strong>
              <span className="">
                We may disclose personal data to regulatory authorities or other
                parties when required by law, court order, or for legitimate
                legal interests, such as enforcing our policies or protecting
                our rights and property.
              </span>
            </li>
          </ul>
        </div>
        <div>
          <h3 className="text-lg md:text-3xl font-bold mt-4">
            7. Data Retention
          </h3>
          <ul className="list-disc ml-8">
            <li>
              Thrypes retains personal data only as long as necessary to fulfill
              the purposes for which it was collected or as required by law.
            </li>
            <li>
              Once personal data is no longer needed, it will be securely
              deleted or anonymized in accordance with GDPR requirements.
            </li>
          </ul>
        </div>
        <div>
          <h3 className="text-lg md:text-3xl font-bold mt-4">
            8. Your Rights Under GDPR
          </h3>
          <p>
            As a user, you have the following rights concerning your personal
            data:
          </p>
          <ul className="list-disc ml-8">
            <li>
              <strong>Access: </strong>
              <span className="">
                Request a copy of the personal data we hold about you.
              </span>
            </li>
            <li>
              <strong>Rectification: </strong>
              <span className="">
                Request correction of any inaccurate or incomplete data.
              </span>
            </li>
            <li>
              <strong>Erasure: </strong>
              <span className="">
                Request deletion of your personal data when it is no longer
                needed or consent has been withdrawn.
              </span>
            </li>
            <li>
              <strong>Restriction of Processing: </strong>
              <span className="">
                Request limited processing of your data in certain
                circumstances.
              </span>
            </li>
            <li>
              <strong>Data Portability: </strong>
              <span className="">
                Request the transfer of your data to another data controller.
              </span>
            </li>
            <li>
              <strong>Objection: </strong>
              <span className="">
                Object to processing based on legitimate interests.
              </span>
            </li>
            <li>
              <strong>Withdraw Consent: </strong>
              <span className="">
                Withdraw any consent previously provided for specific data
                processing.
              </span>
            </li>
          </ul>
          <p className="mt-2">
            To exercise these rights, please contact us at{' '}
            <a
              href="mailto:data.privacy@thrypes.com"
              className="font-semibold underline"
            >
              data.privacy@thrypes.com.
            </a>{' '}
            We may request verification of identity before processing requests
            to protect user privacy.
          </p>
        </div>
        <div>
          <h3 className="text-lg md:text-3xl font-bold mt-4">
            9. Data Security
          </h3>
          <p>
            Thrypes takes reasonable technical and organizational measures to
            protect your personal data from unauthorized access, disclosure,
            alteration, or destruction. We utilize secure servers and encryption
            technology to safeguard information collected through our website.
          </p>
        </div>
        <div>
          <h3 className="text-lg md:text-3xl font-bold mt-4">
            10. International Data Transfers
          </h3>
          <ul className="list-disc ml-8">
            <li>
              Thrypes may transfer data outside the European Economic Area (EEA)
              only when necessary and in compliance with GDPR.
            </li>
            <li>
              When transferring data internationally, Thrypes ensures that
              adequate safeguards, such as{' '}
              <strong>Standard Contractual Clauses (SCCs)</strong>, are in place
              to maintain the security and privacy of personal data.
            </li>
          </ul>
        </div>
        <div>
          <h3 className="text-lg md:text-3xl font-bold mt-4">
          11. Third-Party Links
          </h3>
          <p>Our website may contain links to external websites operated by third parties. Thrypes is not responsible for the privacy practices or content of external sites. Users are encouraged to review the privacy policies of these websites.</p>
        </div>
        <div>
          <h3 className="text-lg md:text-3xl font-bold mt-4">
          12. Children’s Privacy
          </h3>
          <p>Thrypes does not knowingly collect or process personal data from children under the age of 16. If we become aware that we have collected personal data from a child without verified parental consent, we will promptly delete such data.</p>
        </div>
        <div>
          <h3 className="text-lg md:text-3xl font-bold mt-4">
          13. Changes to This Privacy Policy
          </h3>
          <ul className="list-disc ml-8">
            <li>
            Thrypes may update this Privacy Policy to reflect legal or regulatory changes, operational practices, or other developments. Any changes will be posted on this page with the updated “Last Updated” date.
            </li>
            <li>
            Users are encouraged to review this policy periodically to stay informed about our data protection practices.
            </li>
          </ul>
        </div>
        <div>
          <h3 className="text-lg md:text-3xl font-bold mt-4">
          14. Contact Us
          </h3>
          <p>For inquiries or concerns regarding this Privacy Policy or data processing practices, please contact:</p>
          <ul className="list-disc ml-8">
            <li>
              <strong>Thrypes GmbH</strong>
            </li>
            <li>
              <strong> Gontardstraße 11, 4th Floor, 10178 Berlin, Germany</strong>
            </li>
            <li>
              <strong>Email: </strong>
              <a
                href="mailto:data.privacy@thrypes.com"
                className="font-semibold underline"
              >
                data.privacy@thrypes.com
              </a>
            </li>
            <li>
              <strong>Phone: </strong>
              <span>+49 (030) 25093949</span>
            </li>
          </ul>
          <p className="mt-4">By using the Thrypes website, you acknowledge that you have read, understood, and agree to the practices described in this Privacy Policy.</p>
        </div>
      </div>
    </div>
  )
}

export default PrivacyPolicy
