import React, { useState } from 'react'
import { apiRequest } from '../Redux/ApiCalls'

import Notification from '../Components/Notification'
import Loading from '../Components/Loading'
import emailIcon from '../Assets/Icons/envelope.svg'
import mapIcon from '../Assets/Icons/marker.svg'
import phoneIcon from '../Assets/Icons/phone-call.svg'

const Contacts = () => {
  const [failureMessage,setFailureMessage] = useState(null)
  const [successMessage,setSuccessMessage] = useState(null)
  const [isLoading,setIsLoading] = useState(false)
  const [inputs,setInputs] = useState({
    email:'',
    name:'',
    subject:'',
    content:''
  })
  const { name,email,subject,content } = inputs

  const handleChange = (e) => {
    setInputs({ ...inputs,[e.target.name]:e.target.value })
  }

  const handleSubmit = async(e) => {
    e.preventDefault()
    setIsLoading(true)
    if(!name || !email || !subject || !content) {
      setFailureMessage('All fields are required')
      setIsLoading(false)
      setTimeout(() => {
        setFailureMessage(null)
      }, 5000)
    } else {
      try {
        const res = await apiRequest.post('/contacts',{ ...inputs },{ timeout: 30000 })
        setSuccessMessage(res.data?.message)
        setIsLoading(false)
        setTimeout(() => {
          setSuccessMessage(null)
        }, 5000)
        setInputs({
          email:'',
          name:'',
          subject:'',
          content:''
        })
      } catch (error) {
        if (error.message) {
          setFailureMessage(error.message)
        } else {
          setFailureMessage(error.response?.data.message)
        }
        setIsLoading(false)
        setTimeout(() => {
          setFailureMessage(null)
        }, 5000)
      }
    }
  }

  return (
    <div className='px-4 md:px-20 pt-8 pb-4'>
      <Notification success={successMessage} failure={failureMessage} color={successMessage? 'green' : 'red'} />
      <div className='flex flex-col items-center justify-center'>
        <h2 className='mb-4 font-bold text-3xl md:text-5xl text-center'>We are happy to help!</h2>
        <p className='text-text text-xl md:text-2xl text-center'>Contact form is working, we check it daily!</p>
        <p className='text-tex text-center'>Your message will be answered by one of the company partners the same or next business day.</p>
      </div>
      <div className='flex items-center justify-center my-8'>
        <form className='w-full md:w-2/3 lg:w-1/3 p-4 border rounded-lg' onSubmit={handleSubmit}>
          <div className='flex flex-col w-full my-2'>
            <label htmlFor="email" className='mb-1 text-lg font-bold'>Email*</label>
            <input type='email' name='email' value={email} placeholder='Your business email' maxLength='50'
              className='p-2 border rounded-lg' onChange={handleChange} />
          </div>
          <div className='flex flex-col w-full my-2'>
            <label htmlFor="email" className='mb-1 text-lg font-bold'>Fullname*</label>
            <input type='text' name='name' value={name} placeholder='Your full name' maxLength='50'
              className='p-2 border rounded-lg' onChange={handleChange} />
          </div>
          <div className='flex flex-col w-full my-2'>
            <label htmlFor="email" className='mb-1 text-lg font-bold'>Subject*</label>
            <input type='text' name='subject' value={subject} placeholder='What do you want to tell us about' maxLength='70'
              className='p-2 border rounded-lg' onChange={handleChange} />
          </div>
          <div className='flex flex-col w-full my-2'>
            <textarea rows="5" type='text' value={content} name='content' maxLength='250'
              placeholder='How can we help you' className='p-2 border rounded-lg' onChange={handleChange} />
          </div>
          <button data-testid='submit-button' type='submit' className='px-4 py-2 text-md text-white bg-headers rounded-lg
          font-semibold shadow-sm flex items-center justify-start' disabled={isLoading}>
            {isLoading && <div className="w-full mr-2"><Loading color={'white'} /></div>}
            {isLoading? 'Contacting...' : 'Contact Us'}
          </button>
        </form>
      </div>
      <div className='bg-nuance rounded-lg flex flex-col items-center justify-center p-4 my-8'>
        <h2 className='text-headers text-4xl font-bold mb-4 text-center'>Please contact us via:</h2>
        {/* <h3 className='text-2xl font-bold mb-4 text-center'>08:00 AM to 05:00 PM Berlin</h3> */}
        <div className='flex flex-col items-start justify-center'>
          <div className='flex items-center justify-center mb-2'>
            <img src={emailIcon} alt='emailIcon' className='w-5 mr-4' />
            <span className='text-black font-semibold'>support@thrypes.com</span>
          </div>
          <div className='flex items-center justify-center mb-2'>
            <img src={phoneIcon} alt='phoneIcon' className='w-5 mr-4' />
            <span className='text-black font-semibold'>+49 (030) 25093949</span>
          </div>
          <div className='flex items-center justify-center mb-2'>
            <img src={mapIcon} alt='mapIcon' className='w-5 mr-4' />
            <span className='text-black font-semibold'>Gontardstraße 11, 4th Floor, <br/> 10178 Berlin, Germany</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contacts